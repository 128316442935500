/**
 * Created by neo on 23.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { ArticleEntry } from '../../../Model/Explore/ArticleEntry';
import { Col, Container, Row } from 'reactstrap';
import { ExploreContentViewVideoPlayer } from '../ExploreContentViewVideo/ExploreContentViewVideoPlayer';
import { ExploreContentViewArticleContent } from './ExploreContentViewArticleContent';
import { LanguageDisclaimer } from '../LanguageDisclaimer';
import { useRootStore } from '../../../Store/useRootStore';
import styled from '@emotion/styled';

const MediaContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 16px;
  background-size: cover !important;
  background-position: center 30% !important;
  background-repeat: no-repeat !important;
  height: 300px;
  border-radius: 16px 16px 0px 0px;

  @media (min-width: 600px) {
    height: 360px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 560px;
  }
`;

export type ExploreContentViewArticleProps = {
  entry: ArticleEntry;
};

export const ExploreContentViewArticle: React.FC<ExploreContentViewArticleProps> = observer(({ entry }) => {
  const { language } = useRootStore();

  const image = entry.getImage(language.language)?.largeOrMediumOrSmallest;

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <MediaContainer style={{ background: `url('${image}')` }}>
            <ExploreContentViewVideoPlayer entry={entry} />
          </MediaContainer>
        </Col>
        <Col xs={12} md={5} xl={3}>
          <LanguageDisclaimer availableLanguages={entry.availableLanguages} />
        </Col>
        <Col xs={12}>
          <ExploreContentViewArticleContent entry={entry} />
        </Col>
      </Row>
    </Container>
  );
});
