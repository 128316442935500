import { toJS } from 'mobx';
import __DEV__ from './Dev';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export function logger(message: string, ...args: any[]) {
  if (__DEV__) {
    const jsons = (args || [])
      .map((arg) => (typeof arg === 'function' ? toJS(arg()) : toJS(arg)))
      .map((arg) => JSON.stringify(arg, getCircularReplacer(), 2));
    console.log(message, ...jsons);
  }
}
